export type SearchConfig = {
  showInput: boolean;
  viewMode: "GRID" | "LISTING";
  viewModeOptions: any[];
  forcedFilterOptions: null | string[];
  requiredPreFilters: null | string[];
  headings: { heading: string; value: string }[];
  buttonLabel: string;
  returnFacetList: boolean;
  params: {
    includeSKUCount: boolean;
    includeResizedImages: boolean;
    applyStockFilter: boolean;
    productsListingFlag: boolean;
    includeSettings: boolean;
    includePagination: boolean;
    includePotentialFilters: boolean;
  };
  filters: any;
  showFilterSidebar: boolean;
  searchCustomise: {
    showSidebar: boolean;
    showSearchBar: boolean;
    facets: { facetsToShow: string } | null;
    sorting: { sortingToShow: string } | null;
    preFilters: { slug: string; value: string }[];
  } | null;
  listingTopBar: {
    type: "Hidden" | "Product Type";
    hideSidebarWithCount: number;
    showWithRecordCount: number;
    displayType: "All" | "Parent" | "Children";
    pageSize: number;
    actionType: "Facets" | "Link";
  } | null;
  noProductFoundLink: {
    label: string;
    link: string;
  };
};
export type SkuCardConfiguration = any;
export type ProductCardConfiguration = any;

const preFilter = {};
const GRID = "GRID";
const LISTING = "LISTING";
const LINEBYLINE = "LISTINGLINEBYLINE";
const viewModes = {
  LISTINGLINEBYLINE: { name: "frontend.bulkorder.linebyline" },
  GRID: { name: "frontend.bulkorder.grid" },
  LISTING: { name: "frontend.bulkorder.list" },
};
export { preFilter, GRID, LISTING, LINEBYLINE, viewModes };
