import { GRID, LISTING } from "../../productListing.moduleModels";
import { SearchListingModel } from "./SearchListing.componentModel";
import { useElementContext } from "../../../../contexts";

export const SearchListingView = ({
  componentData: {
    hide,
    viewMode,
    showFilterSidebar,
    showForcedPreFilters,
    searchConfig,
    searchListingData,
    cardDisplayConfigurations,
    listingTopbar,
    customStyleClasses,
  },
  setViewMode,
}: {
  componentData: SearchListingModel;
  setViewMode: (mode: any) => void;
}) => {
  const {
    records,
    isFetching,
    potentialFilters,
    total,
    totalPages,
    sortBy,
    setSort,
    updateAttribute,
    setPage,
    params,
  } = searchListingData;
  const {
    ProductListingModule,
    CommonModule: { ListingPagination },
  } = useElementContext();

  if (showForcedPreFilters) {
    return (
      <div className={`container cetSearchPreFilter ${customStyleClasses || ""}`}>
        {searchConfig?.requiredPreFilters?.map((filter: string, idx: number) => {
          return (
            <ProductListingModule.CoreControl.SearchPreFilter
              key={idx}
              params={params}
              potentialFilters={potentialFilters}
              requiredFilterSlug={filter}
            />
          );
        })}
      </div>
    );
  }
  return (
    <div className={`container product-listing mb-5 py-2 px-0 ${customStyleClasses || ""}`}>
      <ProductListingModule.CoreControl.ListingToolBar
        sortBy={sortBy}
        setSort={setSort}
        recordsCount={total}
        searchConfig={searchConfig}
      />
      <ProductListingModule.CoreControl.ListingViewToggle
        config={searchConfig}
        viewMode={viewMode}
        setViewMode={setViewMode}
      />
      {listingTopbar && <ProductListingModule.CoreControl.ListingTopbar {...listingTopbar} />}
      <div className="row">
        {(searchConfig.searchCustomise?.showSidebar ?? true) &&
          (showFilterSidebar || showFilterSidebar !== false) &&
          (!listingTopbar || (listingTopbar.listingTopBar?.hideSidebarWithCount || 1) > listingTopbar.items.length) && (
            <ProductListingModule.CoreControl.ListingSidebar
              config={searchConfig}
              isFetching={isFetching}
              hide={hide}
              filtering={potentialFilters}
              recordsCount={total}
              updateAttribute={updateAttribute}
            />
          )}

        <div className="col">
          {viewMode === LISTING && (
            <ProductListingModule.CoreControl.ListingListView
              cardDisplayConfigurations={cardDisplayConfigurations}
              config={searchConfig}
              isFetching={isFetching}
              pageRecords={records}
            />
          )}
          {viewMode === GRID && (
            <ProductListingModule.CoreControl.ListingGridView
              cardDisplayConfigurations={cardDisplayConfigurations}
              config={searchConfig}
              isFetching={isFetching}
              pageRecords={records}
            />
          )}
        </div>
      </div>
      <ListingPagination
        recordsCount={total}
        currentPage={params["currentPage"]}
        totalPages={totalPages}
        setPage={setPage}
      />
    </div>
  );
};
